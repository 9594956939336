




















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { EUserStatus, GroupChatCompact } from '@/models'
import { groupchatRef } from '@/firebase'
import GroupChatService from '@/services/GroupChatService'
import FormatDate from '@/helpers/FormatDate'
import ChatService from '@/services/ChatService'
import ModalShowProfile from '@/components/Modal/ModalShowProfile.vue'
//@ts-ignore
import _ from 'lodash'

@Component({
  components: {
    ModalShowProfile
  }
})
export default class NavChatUnread extends Vue {
  private startLoading: boolean = true
  private lstGroupChat: GroupChatCompact[] = []
  private unreadCount: number = 0
  private limit: number = this.$store.state.setting.setting.setting_display
    .number_item_lists
  private page: number = 1
  private maxPage: number = 1
  private avatarDefault = require('@/assets/images/avatar-default.png')
  private userPreviewId: number = -1
  private markReadCount: number = 0
  private ATTACH_FILE: string = 'ATTACH_FILE'
  private MESSAGE_DELETED: string = 'MESSAGE_DELETED'
  private LEAVE_GROUP_CHAT: string = 'LEAVE_GROUP_CHAT'
  private LEAVE_SYSTEM: string = 'LEAVE_SYSTEM'
  private unsubscribe: any

  created() {
    this.getListUnreadGroupChat()
    this.handleRealtime()
  }
  destroyed() {
    this.unsubscribe()
  }

  @Watch('$store.state.userInfo.user.info.id')
  handleRealtime() {
    let trackingRealtime = true
    const userId = this.$store.state.userInfo.user.info.id
    if (!userId) return
    // firebase get change group chat
    const start = this.moment()
      .subtract(1, 'm')
      .toDate()
    this.unsubscribe = groupchatRef
      .where('userIds', 'array-contains', userId)
      .where('updatedAt', '>', start)
      .onSnapshot((querySnapshot: any) => {
        if (trackingRealtime) {
          trackingRealtime = false
        } else {
          this.startLoading = true
          this.getListUnreadGroupChat()
        }
      })
  }

  // get all group chat
  getListUnreadGroupChat() {
    GroupChatService.reloadLstGroupChat(this.limit, this.page)
      .then((res: any) => {
        if (res.status === 200) {
          const data = res.data.data.map(
            (item: any) => new GroupChatCompact(item)
          )
          this.lstGroupChat = data
          this.maxPage = res.data.last_page
          this.unreadCount = res.data.total
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
      .finally(() => (this.startLoading = false))
  }

  async loadMore() {
    this.page++

    const missingDataPage = Math.ceil(this.markReadCount / this.limit)

    for (let i = this.page - missingDataPage; i < this.page + 1; i++) {
      const res = await GroupChatService.reloadLstGroupChat(this.limit, i)
      if (res.status === 200) {
        const groupChatItemIds = this.lstGroupChat.map(item => item.id)
        const data = res.data.data.filter(
          (item: any) => !groupChatItemIds.includes(item.id)
        )

        const dataFiltered = data.map((item: any) => new GroupChatCompact(item))
        this.lstGroupChat = [...this.lstGroupChat, ...dataFiltered]
        this.maxPage = res.data.last_page
      }
    }
  }

  /**
   * Handle chat title
   */
  handleChatTitle(groupChat: GroupChatCompact) {
    if (!groupChat.only_user) return groupChat.name

    if (!groupChat.only_user && !groupChat.user_group_chat.length)
      return groupChat.name

    let names = []

    if (groupChat.only_user && groupChat.user_leave_chat) {
      names = [
        this.hanldeUserStatus(
          groupChat.user_leave_chat.name,
          groupChat.user_leave_chat.leaveType
        )
      ]
    } else {
      names = groupChat.user_group_chat.map(item => item.name)
    }

    if (names.length > 2) {
      return (
        names.join(`${this.$t('common.suffix.san')}${this.$t('chat.comma')}`) +
        this.$t('chat.chat_group_name_with', {
          other_user_count: names.length - 2
        })
      )
    } else {
      return (
        names
          .map(name => `${name}${this.$t('common.suffix.san')}`)
          .join(`${this.$t('chat.comma')}`) + this.$t('chat.chat_with')
      )
    }
  }

  hanldeUserStatus(name: string, status: EUserStatus) {
    if (status === EUserStatus.LEAVE_GROUP_TAG)
      return name + this.$t('common.suffix.leave_group')
    if (status === EUserStatus.LEAVE_SYSTEM)
      return this.$t('common.suffix.leave_system') as string
    return name
  }

  formatDateWithDays(date: string) {
    return FormatDate.formatDateWithDays(date)
  }

  handleLongText(text: string) {
    return _.truncate(text, {
      length: 50,
      separator: ' '
    })
  }

  groupChatLastMess(keyCode: string) {
    let content = '<div>' + keyCode + '</div>'
    let doc = new DOMParser().parseFromString(content, 'text/html')
    let wrapper: any = doc.querySelector('div')
    content = wrapper.innerText
    if (!content) return ''
    const text = content.split(',')[0]
    switch (text) {
      case this.ATTACH_FILE:
        return this.$t('common.message.chat_no_content')
      case this.MESSAGE_DELETED:
        return this.$t('chat.list.msg.msg_deleted')
      case this.LEAVE_GROUP_CHAT:
        return this.$t('chat.list.msg.leave_group_chat', {
          name: content.split(',')[1]
        })
      case this.LEAVE_SYSTEM:
        return this.$t('chat.list.msg.leave_system', {
          name: content.split(',')[1]
        })
      default:
        return keyCode
    }
  }

  private joinGroupChat(groupChatId: number) {
    return {
      name: 'chat-detail',
      params: {
        groupChatId: groupChatId.toString(),
        from: this.$route.path
      }
    }
  }

  handleMarkRead(groupChat: GroupChatCompact) {
    let data: any = {
      group_chat_id: groupChat.id,
      count_message_not_seen: 0
    }

    ChatService.markReadGroupChat(data)
      .then(res => {
        if (res.status === 200) {
          this.lstGroupChat = this.lstGroupChat.filter(
            item => item.id !== groupChat.id
          )
          this.unreadCount = this.unreadCount - 1
        }
      })
      .catch(err => {
        console.log(err)
      })

    this.markReadCount = this.markReadCount + 1
  }

  markReadAll() {
    ChatService.markReadAllGroupChat()
      .then(res => {
        if (res.status === 200) {
          this.lstGroupChat = []
          this.unreadCount = 0
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  handleShowProfile(groupChat: any) {
    if (!groupChat.only_user) return

    let user_id = groupChat.user_group_chat[0].id
    this.userPreviewId = user_id
    this.$bvModal.show('modal-show-profile')
  }
}
