






























































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import CalendarService from '@/services/CalendarService'
import Calendar from '@/store/modules/Calendar'
import store from '@/store'
import FormatDate from '@/helpers/FormatDate'
import PreviewText from '@/helpers/PreviewText'
import { EUserStatus } from '@/models'

const CalendarModule = getModule(Calendar, store)

@Component
export default class CalendarEventUnread extends Vue {
  private eventCalendarUnread: any[] = []
  private page: number = 1
  private maxPage: number = 1
  private disableMarkAllEventCalendarUnread: boolean = false
  private limit = this.$store.state.setting.setting.setting_display
    .number_item_lists
  private markReadCount: number = 0
  private avatarDefault = require('@/assets/images/avatar-default.png')
  private avatarGroupDefault = require('@/assets/images/group/avatar_default.png')
  private eEventStatus: any = EEventStatus

  @Watch('$store.state.calendar.calendar_event_unread_count')
  watchCalendarEventUnreadCount() {
    this.getEventCalendarUnreadCount()
  }

  created() {
    this.getEventCalendarUnreadCount()
    this.getEventCalendarUnread()
  }

  getEventCalendarUnreadCount() {
    CalendarService.getEventCalendarUnreadCount()
      .then(res => {
        if (res.status === 200) {
          let calendar_event_count = res.data
          CalendarModule.SET_NEW_CALENDAR_EVENT_COUNT(calendar_event_count)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  /**
   * Call API get list
   */
  getEventCalendarUnread() {
    CalendarService.getEventCalendarUnread(this.limit, this.page)
      .then(res => {
        if (res.status === 200) {
          this.eventCalendarUnread = res.data.data
          this.maxPage = res.data.last_page
        }
      })
      .catch(err => console.log(err))
  }

  /**
   * Load more
   */
  async loadMore() {
    ++this.page

    const missingDataPage = Math.ceil(this.markReadCount / this.limit)

    for (let i = this.page - missingDataPage; i < this.page + 1; i++) {
      const res = await CalendarService.getEventCalendarUnread(this.limit, i)
      if (res.status === 200) {
        const eventCalendarUnreadIds = this.eventCalendarUnread.map(
          item => item.id
        )
        const data = res.data.data.filter(
          (item: any) => !eventCalendarUnreadIds.includes(item.id)
        )

        this.eventCalendarUnread = [...this.eventCalendarUnread, ...data]
        this.maxPage = res.data.last_page
      }
    }
  }

  markReadCalendarEvent(index: number) {
    let event_select = this.eventCalendarUnread[index]
    let eventIds = { eventIds: [event_select.id] }

    CalendarService.markEventCalendarReaded(eventIds)
      .then(res => {
        if (res.status === 200) {
          this.eventCalendarUnread = this.eventCalendarUnread.filter(
            (item: any) => item.id != event_select.id
          )
          this.watchCalendarEventUnreadCount()
          this.markReadCount = this.markReadCount + 1
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  async markReadAllCalendarEvent() {
    this.disableMarkAllEventCalendarUnread = true

    await CalendarService.markAllEventCalendarReaded()
      .then(res => {
        if (res.status === 200) {
          this.eventCalendarUnread = []
        }
      })
      .catch(err => {
        console.log(err)
      })
    this.disableMarkAllEventCalendarUnread = false
  }

  goToEventDetail(event_id: number) {
    return { path: `calendar/${event_id}` }
  }

  formatCompact(date: string) {
    return FormatDate.formatDateWithDays(date)
  }

  handleLongText(text: string, max?: number) {
    return PreviewText.covertToPreviewText(text, max)
  }

  getUpdaterName(event: any) {
    let text = this.hanldeUserStatus(
      event.update_by_user,
      event.user_active_status
    )
    const preview = PreviewText.covertToPreviewText(text, 20)
    return {
      name: text,
      text: preview.text
    }
  }

  hanldeUserStatus(name: string, status: EUserStatus) {
    if (status === EUserStatus.LEAVE_GROUP_TAG)
      return name + this.$t('common.suffix.leave_group')
    if (status === EUserStatus.LEAVE_SYSTEM)
      return this.$t('common.suffix.leave_system') as string
    return name
  }
}

enum EEventStatus {
  NEW = 1,
  UPDATE = 2
}
