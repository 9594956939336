var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.lstGroupChat.length || _vm.startLoading)?_c('div',{staticClass:"chat-unread-home mb-xl-5"},[_c('Title',[_vm._v(_vm._s(_vm.$t('chat.list.title')))]),_c('div',{staticClass:"border-top border-xl py-4"},[(_vm.unreadCount > 0)?_c('div',{staticClass:"d-flex justify-content-between align-items-start mb-2 ps-2 pe-5 ps-xl-4 pe-xl-11"},[_c('div',{staticClass:"me-4"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('common.message.unread_post', { count: _vm.unreadCount }))+" ")])]),_c('button',{staticClass:"btn btn-outline-secondary-deep text-nowrap btn-light",on:{"click":_vm.markReadAll}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_mark_as_read'))+" ")])]):_vm._e(),_c('div',{staticClass:"ms-xl-15 me-xl-6"},[(_vm.startLoading)?_c('div',[_c('LoadingCommonTopic'),_c('LoadingCommonTopic'),_c('LoadingCommonTopic')],1):_vm._e(),_vm._l((_vm.lstGroupChat),function(groupChat,index){return _c('div',{key:index,staticClass:"d-flex border-top-xl border-secondary hover-bg-secondary-deep-less"},[_c('div',{staticClass:"d-flex flex-1 align-items-center px-xl-3"},[_c('router-link',{staticClass:"d-flex flex-column justify-content-between align-items-center px-2 flex-fixed",attrs:{"to":_vm.joinGroupChat(groupChat.id)}},[_c('div',{staticClass:"d-flex flex-fixed"},[_c('div',{staticClass:"d-block"},[(groupChat.user_group_chat.length)?_c('ul',{staticClass:"avatar-group h--10 w--10 h-xl--12 w-xl--12 mb-0"},_vm._l((groupChat.user_group_chat),function(avatar,index){return _c('li',{key:index,staticClass:"avatar-user"},[_c('img',{staticClass:"img-avatar",attrs:{"src":avatar.user_profile && avatar.user_profile.avatar
                          ? avatar.user_profile.avatar
                          : _vm.avatarDefault}})])}),0):_c('ul',{staticClass:"avatar-group h--10 w--10 h-xl--12 w-xl--12 mb-0"},[_c('li',{staticClass:"avatar-user"},[_c('img',{staticClass:"img-avatar",attrs:{"src":_vm.avatarDefault}})])])])])]),_c('div',{staticClass:"pe-3 py-2 w-100 cursor-pointer d-xl-flex justify-content-xl-between py-xl-4 pe-xl-0"},[_c('router-link',{staticClass:"content pe-13 mb-1 w-xl-50",attrs:{"to":_vm.joinGroupChat(groupChat.id)}},[_c('div',{staticClass:"text-line-clamp-1 fwb",on:{"click":function($event){$event.stopPropagation();}}},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(_vm.handleChatTitle(groupChat)),expression:"handleChatTitle(groupChat)",modifiers:{"hover":true}}],on:{"click":function($event){return _vm.handleShowProfile(groupChat)}}},[_vm._v(" "+_vm._s(_vm.handleLongText(_vm.handleChatTitle(groupChat)))+" ")])]),_c('div',{staticClass:"text-line-clamp-1 img-child-m-30 all-child-reset chat h--6 pre-wrap-all",class:{
                  'text-secondary-tint-less':
                    !groupChat.last_message ||
                    groupChat.last_message.includes(_vm.ATTACH_FILE) ||
                    groupChat.last_message.includes(_vm.MESSAGE_DELETED) ||
                    groupChat.last_message.includes(_vm.LEAVE_GROUP_CHAT) ||
                    groupChat.last_message.includes(_vm.LEAVE_SYSTEM)
                },domProps:{"innerHTML":_vm._s(_vm.groupChatLastMess(groupChat.last_message))}})]),_c('div',{staticClass:"d-flex align-items-center justify-content-between flex-1"},[_c('router-link',{staticClass:"d-flex align-items-center flex-1 h-100 justify-content-xl-between",attrs:{"to":_vm.joinGroupChat(groupChat.id)}},[(groupChat.sender_id === -1)?_c('span',{staticClass:"text-line-clamp-1"},[_vm._v(" "+_vm._s(_vm.$t('common.suffix.system'))+" ")]):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
                    groupChat.sender ? groupChat.sender.name : ''
                  ),expression:"\n                    groupChat.sender ? groupChat.sender.name : ''\n                  ",modifiers:{"hover":true}}],staticClass:"text-line-clamp-1"},[_vm._v(" "+_vm._s(_vm.handleLongText( groupChat.sender ? groupChat.sender.name : '' ))+" ")]),_c('span',{staticClass:"flex-fixed px-3"},[_vm._v(" "+_vm._s(_vm.formatDateWithDays(groupChat.updated_at))+" ")])]),_c('div',{staticClass:"minw--30"},[_c('button',{staticClass:"btn btn-outline-secondary-deep btn-light",on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.handleMarkRead(groupChat)}]}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_mark_as_read'))+" ")])])],1)],1)],1),_c('div',{staticClass:"d-none d-xl-block border-top-xl border-secondary"})])})],2),(!_vm.lstGroupChat.length && !_vm.startLoading)?_c('div',{staticClass:"text-secondary text-center fs-12 fs-xl-15 py-4"},[_vm._v(" "+_vm._s(_vm.$t('common.message.no_unread'))+" ")]):_vm._e(),(this.page < this.maxPage)?_c('div',{staticClass:"text-center py-4 border-top-xl border-secondary mx-xl-6"},[_c('button',{staticClass:"btn btn-outline-secondary-deep text-nowrap",on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_see_more'))+" ")])]):_vm._e()]),_c('ModalShowProfile',{attrs:{"userId":_vm.userPreviewId}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }