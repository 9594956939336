
















































































































import { Component, Vue } from 'vue-property-decorator'
import GroupService from '@/services/GroupService'
import FormatDate from '@/helpers/FormatDate'
import { TopicFileCompact } from '@/models'
import ListTopic from '@/views/Group/TopPage/NewFeed/ListTopic.vue'
import SharedFolderList from '@/views/Group/TopPage/SharedFolder/SharedFolderList.vue'
//@ts-ignore
import _ from 'lodash'
import PreviewText from '@/helpers/PreviewText'

import { getModule } from 'vuex-module-decorators'
import CommonTopic from '@/store/modules/CommonTopic'
import store from '@/store'

const CommonTopicModule = getModule(CommonTopic, store)

@Component({ components: { ListTopic, SharedFolderList } })
export default class UnreadGroup extends Vue {
  private groupUnread: Groups[] = []
  private showLoadmore: boolean = false
  private isLoading: boolean = true
  private pageGroup: number = 1
  private pageGroupData: PageGroupData[] = []
  private noGroupAvatar = require('@/assets/images/group/avatar_default.png')
  private limit = this.$store.state.setting.setting.setting_display
    .number_item_lists
  created() {
    this.getGroupUnread()
  }

  /**
   * Call API
   *
   * get groups unread
   */
  getGroupUnread(loadMore?: boolean) {
    this.isLoading = true
    GroupService.getGroupUnread(this.pageGroup, this.limit)
      .then(res => {
        if (res.status === 200) {
          //set show loadmore
          this.showLoadmore = this.pageGroup < res.data.last_page ? true : false

          //convert group data to TopicFileCompact
          res.data.data.forEach((item: any) => {
            item.data = item.data.map(
              (i: any) => new TopicFileCompact(i, item.icon_image_small_path)
            )
          })

          //handle groups when loadmore
          this.handleGroupUnreadLoadMore(res, loadMore)
        }
      })
      .finally(() => (this.isLoading = false))
  }

  /**
   * Handle call API group unread
   */
  handleGroupUnreadLoadMore(res: any, loadMore?: boolean) {
    if (loadMore) {
      this.groupUnread = [...this.groupUnread, ...res.data.data]

      //handle group data page
      const newGroupDataPage = res.data.data.map((item: any) => {
        return {
          groupId: item.id,
          page: 2,
          maxPage: item.count_unread <= this.limit ? 2 : 3,
          markReadCount: 0
        }
      })
      this.pageGroupData = [...this.pageGroupData, ...newGroupDataPage]
    } else {
      this.groupUnread = res.data.data

      //handle group data page
      this.pageGroupData = this.groupUnread.map(item => {
        return {
          groupId: item.id,
          page: 2,
          maxPage: item.count_unread <= this.limit ? 2 : 3,
          markReadCount: 0
        }
      })
    }
  }

  /**
   * loadmore group
   */
  loadmoreGroup() {
    this.pageGroup++
    this.getGroupUnread(true)
  }

  /**
   * get page group data
   */
  getPageGroupData(groupId: number) {
    return this.pageGroupData.filter(item => item.groupId === groupId)[0]
  }

  /**
   * Call API get group unread data more
   */
  async loadmoreGroupData(index: number) {
    const groupId = this.groupUnread[index].id
    //get page to loadmore
    const groupDataPage = this.pageGroupData.find(
      item => item.groupId === groupId
    )
    if (!groupDataPage) return

    const loadMorePage = groupDataPage.page
    const markReadCount = groupDataPage.markReadCount
    const missingDataPage = Math.ceil(markReadCount / this.limit)

    this.showLoadmore = true
    for (let i = loadMorePage - missingDataPage; i < loadMorePage + 1; i++) {
      const res = await GroupService.getTopicFileCompact({
        group_id: String(groupId),
        order_by: 'updated_at',
        sorted_by: 'desc',
        page: i,
        limit: this.limit,
        unread_only: '1'
      })
      if (res.status === 200) {
        const groupDataItemIds = this.groupUnread[index].data.map(
          item => item.id
        )
        const data = res.data.data.filter(
          (item: any) => !groupDataItemIds.includes(item.id)
        )

        const dataFiltered = data.map(
          (item: any) =>
            new TopicFileCompact(
              item,
              this.groupUnread[index].icon_image_small_path
            )
        )
        this.groupUnread[index].data = [
          ...this.groupUnread[index].data,
          ...dataFiltered
        ]

        //set last page agian
        this.pageGroupData.forEach(item => {
          if (item.groupId === groupId) {
            item.maxPage = res.data.last_page
          }
        })
      }
    }

    this.pageGroupData.forEach(item => {
      if (item.groupId === groupId) {
        item.page = item.page + 1
      }
    })

    this.showLoadmore = false
  }

  /**
   * mark read
   */
  markReadGroup(index: number, itemUreadIndex: number) {
    const group = this.groupUnread[index]
    this.pageGroupData.forEach(item => {
      if (item.groupId === group.id) {
        item.markReadCount = item.markReadCount + 1
      }
    })
    _.remove(
      group.data,
      (item: any) => item.id === group.data[itemUreadIndex].id
    )
    group.count_unread = group.count_unread - 1
  }

  /**
   * mark read all
   */
  markReadAllGroup(index: number) {
    let group_id = Object(this.groupUnread[index]).id
    this.$blockui.show()
    GroupService.markReadAllGroup(group_id)
      .then(res => {
        if (res.status === 200) {
          this.groupUnread = this.groupUnread.filter(
            (item: any) => item.id !== group_id
          )
          //handle rebind unred count
          CommonTopicModule.SET_RELOAD_UNREAD(true)
        }
      })
      .catch(err => console.log(err))
      .finally(() => this.$blockui.hide())
  }

  /**
   * Handle long text
   */
  handleLongText(text: string) {
    return PreviewText.covertToPreviewText(text, 40)
  }

  /**
   * Format date
   */
  formatDate(date: string) {
    return FormatDate.formatCompact(date)
  }
}

class Groups {
  id!: number
  count_unread: number = 0
  data: TopicFileCompact[] = []
  icon_image_path: string = ''
  icon_image_small_path: string = ''
  name: string = ''
}

interface PageGroupData {
  groupId: number
  page: number
  maxPage: number
  markReadCount: number
}
