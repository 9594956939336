





























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import ListTopic from '@/views/Group/TopPage/NewFeed/ListTopic.vue'
import SharedFolderList from '@/views/Group/TopPage/SharedFolder/SharedFolderList.vue'
import CommonTopicService from '@/services/CommonTopicService'
import { getCommonTopicCount } from '@/helpers/GetNotificationCount'
import { TopicFileCompact } from '@/models'
import { updateCommonTopicUserRole } from '@/helpers/UpdateGroupUserRole'

//@ts-ignore
import _ from 'lodash'
import { getModule } from 'vuex-module-decorators'
import CommonTopicVueX from '@/store/modules/CommonTopic'
import store from '@/store'

const CommonTopicModule = getModule(CommonTopicVueX, store)

@Component({
  components: {
    ListTopic,
    SharedFolderList
  }
})
export default class CommonTopic extends Vue {
  private topicFileCompactHome: TopicFileCompact[] = []
  private isLoadingLoadMore: boolean = false
  private unreadCount = this.$store.state.commonTopic.common_topic_unread_count
  private page: number = 1
  private maxPage: number = 1
  private limit = this.$store.state.setting.setting.setting_display
    .number_item_lists
  private markReadCount: number = 0

  async created() {
    await getCommonTopicCount()
    this.getCommonTopicFile()
    updateCommonTopicUserRole()
  }

  @Watch('$store.state.commonTopic.common_topic_unread_count')
  watchUnreadCount() {
    this.unreadCount = this.$store.state.commonTopic.common_topic_unread_count
  }

  // @Watch('$store.state.commonTopic.reloadUnread')
  handleCommonTopicCount() {
    getCommonTopicCount()
    //handle rebind unred count
    CommonTopicModule.SET_RELOAD_UNREAD(false)
  }

  async getCommonTopicFile() {
    this.isLoadingLoadMore = this.page > 1 ? true : false
    await this.getCommonTopicHome()
    this.isLoadingLoadMore = false
  }

  /**
   * Call API to get 5 newest common topic
   */
  async getCommonTopicHome() {
    return CommonTopicService.getCommonTopicHome(this.limit, this.page)
      .then(res => {
        if (res.status === 200) {
          const data = res.data.data.map(
            (item: any) => new TopicFileCompact(item)
          )
          this.topicFileCompactHome = data
          this.maxPage = res.data.last_page
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  /**
   * Load more for topc-file
   */
  async loadMoreCommonTopicHome() {
    ++this.page

    const missingDataPage = Math.ceil(this.markReadCount / this.limit)

    for (let i = this.page - missingDataPage; i < this.page + 1; i++) {
      const res = await CommonTopicService.getCommonTopicHome(this.limit, i)
      if (res.status === 200) {
        const groupDataItemIds = this.topicFileCompactHome.map(item => item.id)
        const data = res.data.data.filter(
          (item: any) => !groupDataItemIds.includes(item.id)
        )

        const dataFiltered = data.map((item: any) => new TopicFileCompact(item))
        this.topicFileCompactHome = [
          ...this.topicFileCompactHome,
          ...dataFiltered
        ]
        this.maxPage = res.data.last_page
      }
    }
  }

  /**
   * Mark read for all common topic
   */
  async markReadAllCommonTopic() {
    this.$blockui.show()
    await CommonTopicService.markReadAllCommonTopic()
      .then(res => {
        if (res.status === 200) {
          this.topicFileCompactHome = []
          this.unreadCount = 0
          //handle rebind unred count
          CommonTopicModule.SET_RELOAD_UNREAD(true)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        getCommonTopicCount()
        this.$blockui.hide()
      })
  }

  /**
   * remove read item out of array
   */
  subtractReadCount(id: number) {
    this.topicFileCompactHome = this.topicFileCompactHome.filter(
      item => item.id !== id
    )
    this.unreadCount = this.unreadCount - 1
    this.markReadCount = this.markReadCount + 1
  }
}
