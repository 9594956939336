










import { Component, Vue } from 'vue-property-decorator'
import FavouriteNotification from '@/components/FavouriteNotification.vue'
import NavChatUnread from '@/components/NavChatUnread.vue'
import CalendarEventUnread from '@/views/Home/CalendarEventUnread.vue'
import CommonTopic from '@/views/Home/CommonTopic.vue'
import UnreadGroup from '@/views/Home/UnreadGroup.vue'
import { EFrom } from '@/models'

@Component({
  components: {
    FavouriteNotification,
    NavChatUnread,
    CalendarEventUnread,
    CommonTopic,
    UnreadGroup
  }
})
export default class Home extends Vue {
  private eFrom = EFrom
}
